<template>
  <div>
    <titulo-relatorio-faturamento />
    <b-row>
      <b-col md="3">
        <input-date-only-btn
          ref="peridioInicio"
          v-model="filtro.periodoInicial"
          :label="$t('FINANCEIRO.PERIODO_INICIAL')"
          required
        />
      </b-col>

      <b-col md="3">
        <input-date-only-btn
          ref="periodoFim"
          v-model="filtro.periodoFinal"
          :label="$t('FINANCEIRO.PERIODO_FINAL')"
          required
        />
      </b-col>

      <b-col md="auto">
        <b-button
          class="ajuste-fino-btn w-100"
          variant="info"
          @click="gerarRelatorioPdf"
        >
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>

// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';

// Services:
import FluxoCaixaService from '@/common/services/fluxo-caixa/fluxo-caixa.service.js';

// Components:
import { InputDateOnlyBtn } from '@/components/inputs';
import TituloRelatorioFaturamento from '@/views/financeiro/relatorios/RelatorioFaturamento/TituloRelatorioFaturamento'

export default {
  components: {
    TituloRelatorioFaturamento,
    InputDateOnlyBtn,
  },

  template: {},
  data() {
    return {
      filtro: {
        periodoInicial: '',
        periodoFinal: '',
      },
    };
  },
  methods: {
    gerarRelatorioPdf() {
        // TODO: falta integrar com backend
      if (!this.validarFiltro()) return;
      this.$store.dispatch(START_LOADING);
      FluxoCaixaService.gerarRelatorioFaturamentoPdf(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], { type: 'application/pdf' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
  
<style scoped>
.ajuste-fino-btn {
  margin-top: 29px;
}
</style>
  